<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <div>
            <BasicFilter :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" :hideOperators="hideOperators"></BasicFilter>
            
            <div class="row row-cols-2 mx-1 mt-1">
                <div class="d-flex form-check col mb-2 align-items-center" v-for="item in filterObject.dateExpressions">
                    <input class="form-check-input" :checked="filterItem.dateExpression===item.id" type="radio" @click="applyExpression(item.id)" :value="item.id" name="date_expression" :id="item.key" >
                    
                    <label class="form-check-label ps-2" :class="{ 'fs-6': isTouch }" :for="item.key" :title="$t(item.title)">
                        {{$t(item.title)}}
                    </label>
                </div>
            </div>
        </div>
    </OMediaQueryProvider>
</template>

<script setup>
    import BasicFilter from 'o365.vue.components.FilterEditor.Basic.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    
    const props = defineProps({
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });

    const applyExpression = (id) => {
        props.filterItem.dateExpression = id;
        props.filterItem.selectedValue = props.filterItem.dateExpressionObject ? props.filterItem.dateExpressionObject.value : null;
        props.filterItem.operator =  props.filterItem.dateExpressionObject.operator;
        props.filterItem.applied = true;
    }
</script>
